import React from "react"
import style from "./priceslayout.module.css"

const PricesLayout = () => {
  return (
    <div className={style.container}>
      <div>
        <p>
          Gyermekpszichológia
          <br />
          Nevelési tanácsadás
        </p>
        <p>
          15000 Ft
          <br />
          50 perc
        </p>
      </div>
      <div>
        <p>Családterápia</p>
        <p>
          30000 Ft
          <br />
          90 perc
        </p>
      </div>
      <div>
        <p>Párterápia</p>
        <p>
          30000 Ft
          <br />
          90 perc
        </p>
      </div>
    </div>
  )
}

export default PricesLayout
