import React from "react"
import HeaderMobile from "../components/Header/HeaderMobile"
import PricesLayout from "../components/Pricespage/PricesLayout/PricesLayout"
import Footer from "../components/Footer/Footer"
import { Helmet } from "react-helmet"

const PricesMobile = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://jakabjanosieszter.hu" />
        <title>Árak - Jakab-Jánosi Eszter, érdi pszichológus</title>
      </Helmet>
      <HeaderMobile />
      <PricesLayout />
      <Footer />
    </>
  )
}

export default PricesMobile
